import React from 'react';

type LoginFormProps = {
  width?: string;
  height?: string;
};

const LogoFull = (props: LoginFormProps) => {
  const { height } = props;

  return (
    <svg
      height={height || '26'}
      viewBox="0 0 165 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.9302 6.71217C23.7285 6.44598 23.508 6.20129 23.2687 5.97274C23.2042 5.90283 20.311 3.01501 20.2437 2.94778C18.9612 1.59798 17.1166 0.834351 15.0785 0.834351H5.41475C4.67263 0.834351 4.01924 1.36405 3.86329 2.09273L0.0343662 20.1215C-0.0624323 20.5732 0.0478105 21.033 0.332828 21.3852C0.394672 21.4605 3.49222 24.5554 3.56482 24.6172C3.84984 24.9668 4.27468 25.1657 4.73178 25.1657C5.03293 25.1657 5.32871 25.077 5.58415 24.9103L12.0885 20.7049H15.0919C19.4613 20.7049 23.8038 17.4192 24.9761 13.2219C25.643 10.8396 25.2611 8.4653 23.9275 6.70948L23.9302 6.71217Z"
        fill="#8000FF"
      />
      <path
        d="M15.0785 2.17871H5.41478C5.30723 2.17871 5.20236 2.26475 5.18085 2.37231L3.44924 10.5141C3.42772 10.6217 3.49495 10.7077 3.6025 10.7077C5.63796 10.7077 7.63577 9.05947 8.06868 7.02401L8.27572 6.05603H14.2557C16.068 6.05603 17.1892 7.61825 16.6165 9.46818C16.1164 11.0788 14.417 12.2592 12.7984 12.2592H12.3843L12.9597 9.55153C12.9946 9.39289 12.8306 9.30147 12.6827 9.39558L6.75114 13.2299L7.24589 10.9013C7.2674 10.7938 7.20018 10.7077 7.09262 10.7077C5.05717 10.7077 3.05935 12.356 2.62645 14.3915L1.34925 20.401C1.31429 20.5597 1.47831 20.6511 1.6262 20.557L8.46394 16.1365H11.8653C15.5786 16.1365 19.421 13.3401 20.4562 9.63489C21.5936 5.57204 19.0849 2.17871 15.0785 2.17871Z"
        fill="#FBF9FF"
      />
      <path
        d="M31.3801 20.3922V5.61133C31.3801 5.51323 31.4572 5.43616 31.5553 5.43616H38.8844C41.1441 5.43616 42.8363 5.86707 43.9609 6.72891C45.0854 7.59075 45.646 8.89752 45.646 10.6492C45.646 12.4009 45.0714 13.7287 43.9188 14.5906C42.7662 15.4524 41.004 15.8833 38.6322 15.8833H35.7068V20.3922C35.7068 20.4903 35.6298 20.5674 35.5317 20.5674H31.5553C31.4572 20.5674 31.3801 20.4903 31.3801 20.3922ZM38.5446 12.5551C39.5045 12.5551 40.2017 12.4044 40.6326 12.0996C41.0635 11.7948 41.2807 11.3044 41.2807 10.6282C41.2807 9.95204 41.0705 9.50361 40.6466 9.20932C40.2227 8.91153 39.5361 8.76439 38.5901 8.76439H35.7068V12.5586H38.5481L38.5446 12.5551Z"
        fill="#FBF9FF"
      />
      <path
        d="M56.997 20.4833L54.0156 15.4419H50.5402V20.3957C50.5402 20.4938 50.4632 20.5709 50.3651 20.5709H46.5183C46.4202 20.5709 46.3432 20.4938 46.3432 20.3957V5.61133C46.3432 5.51323 46.4202 5.43616 46.5183 5.43616H54.0787C58.5841 5.43616 60.8403 7.11078 60.8403 10.46C60.8403 11.462 60.6195 12.2993 60.1816 12.972C59.7437 13.6446 59.071 14.1842 58.1672 14.5941L61.7091 20.3046C61.7827 20.4202 61.6986 20.5709 61.562 20.5709H57.1442C57.0811 20.5709 57.025 20.5393 56.9935 20.4833H56.997ZM53.8054 12.2187C54.7654 12.2187 55.466 12.0786 55.904 11.7948C56.3419 11.5111 56.5626 11.0521 56.5626 10.418C56.5626 9.78388 56.3524 9.34946 55.939 9.07269C55.5221 8.79592 54.8249 8.65929 53.851 8.65929H50.5438V12.2187H53.8089H53.8054Z"
        fill="#FBF9FF"
      />
      <path
        d="M62.4623 20.3922V5.61133C62.4623 5.51323 62.5394 5.43616 62.6375 5.43616H66.7365C66.8346 5.43616 66.9117 5.51323 66.9117 5.61133V20.3922C66.9117 20.4903 66.8346 20.5674 66.7365 20.5674H62.6375C62.5394 20.5674 62.4623 20.4903 62.4623 20.3922Z"
        fill="#FBF9FF"
      />
      <path
        d="M68.243 20.4307L67.6439 17.614C67.6264 17.5264 67.6509 17.4353 67.7139 17.3723L76.2167 8.91153H67.8295C67.7174 8.91153 67.6334 8.80993 67.6579 8.70133L68.3235 5.57629C68.341 5.49571 68.4111 5.43616 68.4952 5.43616H81.1004C81.1845 5.43616 81.2546 5.49571 81.2721 5.57629L81.8712 8.39303C81.8887 8.48061 81.8642 8.5717 81.8011 8.63476L73.2984 17.0955H81.6855C81.7976 17.0955 81.8817 17.1971 81.8572 17.3057L81.1915 20.4307C81.174 20.5113 81.104 20.5709 81.0199 20.5709H68.4146C68.3305 20.5709 68.2605 20.5113 68.243 20.4307Z"
        fill="#FBF9FF"
      />
      <path
        d="M82.5999 20.3922V5.61133C82.5999 5.51323 82.677 5.43616 82.7751 5.43616H95.4224C95.5064 5.43616 95.5765 5.49571 95.594 5.57629L96.2457 8.63827C96.2702 8.74687 96.1861 8.84847 96.074 8.84847H86.9651V11.1572H95.1631C95.2612 11.1572 95.3383 11.2343 95.3383 11.3324V14.3944C95.3383 14.4925 95.2612 14.5695 95.1631 14.5695H86.9651V17.155H96.074C96.1861 17.155 96.2702 17.2566 96.2457 17.3652L95.594 20.4272C95.5765 20.5078 95.5064 20.5674 95.4224 20.5674H82.7751C82.677 20.5674 82.5999 20.4903 82.5999 20.3922Z"
        fill="#FBF9FF"
      />
      <path
        d="M96.9919 20.3922V5.61133C96.9919 5.51323 97.069 5.43616 97.1671 5.43616H104.496C106.756 5.43616 108.448 5.86707 109.573 6.72891C110.697 7.59075 111.258 8.89752 111.258 10.6492C111.258 12.4009 110.683 13.7287 109.531 14.5906C108.378 15.4524 106.616 15.8833 104.244 15.8833H101.319V20.3922C101.319 20.4903 101.242 20.5674 101.143 20.5674H97.1706C97.0725 20.5674 96.9954 20.4903 96.9954 20.3922H96.9919ZM104.156 12.5551C105.116 12.5551 105.813 12.4044 106.244 12.0996C106.675 11.7948 106.893 11.3044 106.893 10.6282C106.893 9.95204 106.682 9.50361 106.258 9.20932C105.834 8.91153 105.148 8.76439 104.202 8.76439H101.319V12.5586H104.16L104.156 12.5551Z"
        fill="#FBF9FF"
      />
      <path
        d="M111.955 20.3922V5.61133C111.955 5.51323 112.032 5.43616 112.13 5.43616H116.229C116.327 5.43616 116.404 5.51323 116.404 5.61133V20.3922C116.404 20.4903 116.327 20.5674 116.229 20.5674H112.13C112.032 20.5674 111.955 20.4903 111.955 20.3922Z"
        fill="#FBF9FF"
      />
      <path
        d="M133.778 14.9724C133.883 14.9724 133.967 15.067 133.953 15.1721C133.722 16.9694 132.916 18.3742 131.539 19.3797C130.113 20.4237 128.204 20.9492 125.818 20.9492C124.038 20.9492 122.497 20.6374 121.197 20.0173C119.897 19.3972 118.892 18.4828 118.177 17.2847C117.462 16.083 117.108 14.6571 117.108 13.0035C117.108 11.434 117.455 10.0501 118.145 8.84847C118.839 7.6468 119.838 6.7149 121.144 6.04925C122.451 5.3836 124.017 5.05428 125.839 5.05428C128.029 5.05428 129.777 5.49922 131.084 6.38908C132.341 7.24742 133.196 8.52265 133.652 10.2183C133.68 10.3234 133.606 10.4285 133.501 10.439L129.388 10.9085C129.307 10.919 129.234 10.8699 129.206 10.7929C128.936 10.0571 128.547 9.51762 128.032 9.17779C127.489 8.81694 126.75 8.63827 125.818 8.63827C124.532 8.63827 123.53 9.02364 122.808 9.79439C122.087 10.5651 121.726 11.6337 121.726 13.0035C121.726 14.3733 122.09 15.4629 122.819 16.2266C123.548 16.9904 124.574 17.3723 125.902 17.3723C126.932 17.3723 127.741 17.1796 128.33 16.7907C128.883 16.4228 129.258 15.8623 129.454 15.1126C129.475 15.0355 129.542 14.9794 129.623 14.9794H133.781L133.778 14.9724Z"
        fill="#FBF9FF"
      />
      <path
        d="M146.05 20.4938L141.334 13.9109L139.131 15.8623V20.3922C139.131 20.4903 139.054 20.5674 138.956 20.5674H134.857C134.759 20.5674 134.682 20.4903 134.682 20.3922V5.61133C134.682 5.51323 134.759 5.43616 134.857 5.43616H138.956C139.054 5.43616 139.131 5.51323 139.131 5.61133V11.1993L145.311 5.4817C145.342 5.45017 145.384 5.43616 145.43 5.43616H150.167C150.328 5.43616 150.401 5.63235 150.286 5.74095L144.365 11.1817L150.986 20.2941C151.07 20.4097 150.986 20.5709 150.846 20.5709H146.194C146.138 20.5709 146.085 20.5428 146.05 20.4973V20.4938Z"
        fill="#FBF9FF"
      />
      <path
        d="M160.439 9.98007C160.365 9.98708 160.295 9.94504 160.263 9.87497C160.022 9.34245 159.671 8.95357 159.212 8.70834C158.718 8.44558 158.039 8.31595 157.177 8.31595C156.203 8.31595 155.464 8.42106 154.963 8.63476C154.462 8.84497 154.209 9.15677 154.209 9.56667C154.209 9.94854 154.343 10.2323 154.612 10.4145C154.882 10.5967 155.404 10.7543 156.182 10.8804L159.447 11.4095C161.412 11.7213 162.828 12.2468 163.697 12.9895C164.566 13.7322 165 14.7657 165 16.0935C165 17.6911 164.355 18.8997 163.073 19.716C161.787 20.5358 159.885 20.9457 157.373 20.9457C154.861 20.9457 152.941 20.5008 151.62 19.6109C150.349 18.7526 149.61 17.4774 149.406 15.7817C149.392 15.6766 149.476 15.5855 149.581 15.5855H153.698C153.779 15.5855 153.845 15.6416 153.866 15.7222C154.024 16.3738 154.367 16.8573 154.896 17.1761C155.46 17.5159 156.287 17.6841 157.377 17.6841C158.393 17.6841 159.177 17.5579 159.727 17.3022C160.277 17.0464 160.554 16.6891 160.554 16.2196C160.554 15.8658 160.404 15.5925 160.099 15.3928C159.794 15.1966 159.268 15.039 158.519 14.9269L155.828 14.524C153.751 14.2122 152.234 13.6692 151.27 12.8914C150.31 12.1136 149.83 11.0486 149.83 9.69279C149.83 8.23888 150.496 7.10027 151.824 6.28048C153.151 5.46068 154.987 5.05078 157.335 5.05078C159.51 5.05078 161.195 5.42565 162.39 6.17537C163.532 6.89357 164.25 7.95861 164.548 9.37398C164.569 9.47558 164.495 9.57718 164.39 9.58419L160.449 9.97657L160.439 9.98007Z"
        fill="#FBF9FF"
      />
    </svg>
  );
};

export default LogoFull;
