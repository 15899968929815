import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  svg: {
    width: 'auto',
    height: 28,
  },
  path: {
    fill: '#7df3e1',
  },
});

const LogoIcon = () => {
  const classes = useStyles();

  return (
    <svg width="36" height="48" viewBox="0 0 51 68" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_3812_74105)">
    <path d="M48.2461 25.8501C47.8396 25.3135 47.3951 24.8202 46.9126 24.3594C46.7825 24.2184 40.9496 18.3964 40.8141 18.2608C38.2283 15.5395 34.5095 14 30.4005 14H10.9177C9.42151 14 8.10423 15.0679 7.78982 16.537L0.0704293 52.8843C-0.124724 53.795 0.097534 54.7219 0.672151 55.4321C0.796832 55.5839 7.04173 61.8233 7.1881 61.948C7.76271 62.6527 8.61922 63.0539 9.54077 63.0539C10.1479 63.0539 10.7442 62.875 11.2592 62.5389L24.3724 54.0606H30.4276C39.2366 54.0606 47.9914 47.4362 50.3549 38.9742C51.6993 34.1712 50.9295 29.3846 48.2407 25.8447L48.2461 25.8501Z" fill="#8000FF"/>
    <path d="M30.4005 16.7104H10.9177C10.7009 16.7104 10.4895 16.8839 10.4461 17.1008L6.95502 33.5153C6.91166 33.7321 7.04718 33.9056 7.26402 33.9056C11.3677 33.9056 15.3954 30.5826 16.2682 26.4789L16.6856 24.5274H28.7417C32.3954 24.5274 34.6559 27.677 33.5013 31.4066C32.493 34.6537 29.067 37.0335 25.8036 37.0335H24.9687L26.1288 31.5746C26.1993 31.2548 25.8686 31.0705 25.5705 31.2602L13.6119 38.9904L14.6094 34.2959C14.6527 34.0791 14.5172 33.9056 14.3004 33.9056C10.1967 33.9056 6.16899 37.2286 5.29622 41.3323L2.72129 53.448C2.65082 53.7678 2.98149 53.9522 3.27964 53.7624L17.065 44.8504H23.9225C31.4088 44.8504 39.1553 39.2127 41.2423 31.7427C43.5354 23.5516 38.4777 16.7104 30.4005 16.7104Z" fill="#FBF9FF"/>
    </g>
    <defs>
    <clipPath id="clip0_3812_74105">
    <rect width="51" height="68" fill="white"/>
    </clipPath>
    </defs>
    </svg>
  );
};

export default LogoIcon;
